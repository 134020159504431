.typeContainer {
  width: 90%;
  padding: 0 5%;
  margin: 0 auto;
}
.inner_banner {
  margin: 20px 0 0;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}
.img_fluid {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
.title {
  font-size: 12vh;
  color: palegreen;
}
.wraper {
  padding-top: 10px;
  padding-bottom: 90px;
  background-color: #f1f5ff;
  height: 90vh;
  width: 100vw;
  font-family: "Roboto", sans-serif;
}
.category_desc {
  padding-top: 15px;
}
.category_desc h4 {
  font-size: 60px;
  color: var(--primary);
  font-weight: 600;
}
.category_desc p {
  font-size: 32px;
  color: #9a9a9a;
}
.green_btn {
  background-color: var(--secondary);
  color: var(--primary);
  font-weight: 600;
  margin: 0 auto;
  height: 30px;
  width: 170px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  border-radius: 15px;
}

.green_btn2 {
  background-color: var(--secondary);
  color: var(--primary);
  font-weight: 600;
  margin: 50px auto 0;
  height: 50px;
  width: 200px;
  line-height: 50px;
  font-size: 22px;
  text-align: center;
  border-radius: 25px;
}

@media screen and (max-width: 900px) {
  .category_desc h4 {
    font-size: 45px;
  }
  .category_desc p {
    font-size: 26px;
  }
}
@media screen and (max-width: 600px) {
  .category_desc h4 {
    font-size: 30px;
    line-height: 36px;
  }
  .category_desc p {
    font-size: 22px;
    line-height: 26px;
    color: #9a9a9a;
  }
}
