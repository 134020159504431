.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: #00000088;
}
.modal .modal_dialog_centered {
  transform: none;
  align-items: end;
  min-height: calc(100% - 0rem);
  display: flex;
  position: relative;
  width: auto;
  margin: 0;
  pointer-events: none;
}
.modal_content {
  border: 0;
  border-radius: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal_header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 1rem 1rem;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  border-bottom: 1px solid #e7e6e6;
  padding-bottom: 15px;
  margin-bottom: 15px;
  justify-content: center;
}

.modal_title {
  margin-bottom: 0;
  line-height: 3rem;
  font-size: 2.5rem;
  color: var(--primary);
}
.modal_Body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal_Body p {
  color: #9a9a9a;
  font-size: 28px;
}
.modal_Body h5 {
  color: #9a9a9a;
}
.list_unstyled {
  padding-left: 0;
  list-style: none;
}
.list_unstyled > li {
  color: #9a9a9a;
  position: relative;
  padding-left: 15px;
  margin-bottom: 20px;
  font-size: 24px;
}
.list_unstyled > li::before {
  content: "";
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: var(--primary);
  position: absolute;
  left: 0;
  top: 8px;
}
.modal_footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-shrink: 0;
  align-items: center;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.leftBtn {
  background-color: var(--secondary);
  color: var(--primary);
  border-color: var(--secondary);
  border-radius: 50px;
  width: 45%;
  font-size: 24px;
  font-weight: 600;
  margin: 0.25rem;
  padding: 0.75rem 1.5rem;
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
}
.rightBtn {
  background-color: #fff;
  border: 1px solid var(--primary);
  color: var(--primary);
  border-radius: 50px;
  width: 45%;
  font-size: 24px;
  font-weight: 600;
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
@media screen and (max-width: 600px) {
  .modal_title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1.25rem;
  }
  .modal_Body p {
    font-size: 16px;
  }
  .list_unstyled > li {
    font-size: 14px;
  }
  .leftBtn {
    border-radius: 25px;
    font-size: 16px;
    padding: 0.375rem 0.75rem;
  }
  .rightBtn {
    border-radius: 25px;
    font-size: 16px;
    padding: 0.375rem 0.75rem;
  }
}
