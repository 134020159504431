.profileHMainDiv {
  width: 100%;
  margin: 30px 0 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.profileImage {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 15px;
  background-color: gray;
}
.userNameDiv {
  color: var(--primary);
  font-weight: 700;
  font-size: 24px;
  width: 100%;
  text-align: center;
  margin: 5px auto;
  text-transform: capitalize;
}
.topButtonsMainDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px 20px;
}
.topButtonDiv {
  width: 31%;
  background-color: white;
  border-radius: 10px;
  padding: 15px 0;
  color: var(--primary);
  font-size: larger;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  cursor: pointer;
}
.topCardImage {
  width: 40px;
  height: 40px;
  margin: 5px auto;
}
.topButtonCSS {
  width: 100%;
  text-align: center;
}
.bottomListDiv {
  width: calc(100% - 10px);
  border-bottom: 1px solid gainsboro;
  background-color: white;
  padding: 15px 5px;
  font-size: larger;
  color: var(--primary);
  cursor: pointer;
}
.listIcon {
  margin: 0px 15px 0 10px;
}
.ratingMainDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5px 0 15px;
  font-size: large;
  font-weight: 500;
  color: var(--primary);
}
.ratingDiv {
  width: 110px;
}
