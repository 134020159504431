.vehicleSpecsMainDiv {
  width: calc(100% - 10vw);
  margin: 20px 5vw;
  font-family: sans-serif;
  color: grey;
  background-color: #f1f5ff;
  display: flex;
  justify-content: space-between;
}
.lMd {
  width: 30%;
}
.lH {
  background-color: var(--primary);
  width: calc(100% - 40px);
  padding: 20px;
  color: #ffffff;
  border-bottom: 1px solid #eeecfa;
}
.lItem {
  width: calc(100% - 40px);
  padding: 20px;
  color: var(--primary);
  cursor: pointer;
  border-bottom: 1px solid #eeecfa;
}

.rMd {
  width: 68%;
  background-color: #ffffff;
}
.rImgMd {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0 30px;
  background-color: #f2f2f2;
}
.rImg {
  width: 100%;
}
.rContent {
  padding: 10px 20px 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.rRContent {
  width: 47.5%;
}
.contentHeading {
  color: var(--primary);
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0;
}
.vehicleSpecsDisclaimer {
  margin: 15px 0 30px;
  font-size: 15px;
}
.BulletMainDiv {
  width: 100%;
  margin: 0 0 10px 0;
  font-size: 15px;
}
.bulletCircle {
  color: var(--primary);
  margin: 0px 8px 2px 0;
  font-size: 8px;
}
.bulletText {
  width: calc(100% - 16px);
}

@media screen and (max-width: 1025px) {
  .vehicleSpecsMainDiv {
    width: calc(100% - 40px);
    margin: 20px 20px 20vh;
  }
}
@media screen and (max-width: 900px) {
  /* .rImg {
    height: auto;
    width: 100%;
  } */
}

@media screen and (max-width: 600px) {
  .vehicleSpecsMainDiv {
    width: calc(100% - 30px);
    margin: 10px 15px;
    display: block;
  }
  .lMd {
    width: 100%;
  }
  .rMd {
    width: 100%;
  }
  .rRContent {
    width: 100%;
  }
}
