.orderSummaryMainDiv {
  width: 100%;
}
.cardMainDiv {
  background-color: #fff;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 00.1);
  width: calc(85% - 40px);
  margin: 10% auto;
  padding: 20px;
  border-radius: 20px;
}
.headingMainDiv {
  color: var(--primary);
  font-size: larger;
  font-weight: 500;
}
.BookingNoDiv {
  color: gray;
  margin: 0 0 10px;
}
.itemMainDiv {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}
.itemKey {
  color: grey;
}
.itemValue {
  color: var(--primary);
}
.buttonsDiv {
  display: flex;
  justify-content: space-between;
  color: var(--primary);
  margin: 10px 0;
}
.acceptBtn {
  height: 40px;
  width: 45%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary);
}
.acceptBtn:hover {
  background-color: var(--primary);
  color: #fff;
}
.rejectBtn {
  height: 40px;
  width: 45%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary);
}
.rejectBtn:hover {
  background-color: var(--primary);
  color: #fff;
}
