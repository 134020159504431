/* Top */
::-webkit-scrollbar {
  width: 0;
}

/* main div */
.MainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* main div */
.MainDiv {
  margin-top: 4vh;
  height: 93vh;
  width: 100vw;
}

.MainDiv1 {
  height: 20vh;
  text-align: center;
  margin-top: 1vh;
}
.MainDiv1 h1 {
  color: rgb(39, 24, 122);
  font-size: 2rem;
  font-weight: 500;
}

.MainDiv2 {
  background-color: white;
  height: 15vh;
  text-align: center;
  color: rgb(39, 24, 122);
  font-size: 12px;
  text-align: center;
  padding: 2vh 10vw 2vh 10vw;
  margin-top: 2vh;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 0 0 5px 0 rgba(0, 0, 0, 0.19);
}

.MainDiv2 h1 {
  font-size: 20px;
}

.MainDiv3 {
  height: 15vh;
  width: 100vw;
  /* background-color: rosybrown; */
  text-align: center;
  color: rgb(39, 24, 122);
  font-size: 12px;
  text-align: center;
  padding: 2vh 10vw 2vh 10vw;
  margin: 2vh 0 0vh 0;
}

.MainDiv10 {
  height: 15vh;
  width: 100vw;
  /* background-color: rosybrown; */
  text-align: center;
  color: rgb(39, 24, 122);
  font-size: 12px;
  text-align: center;
  padding: 2vh 10vw 2vh 10vw;
  /* margin: 2vh 0 3vh 0;  */
}

.MainDiv4 h1 {
  font-size: 20px;
  text-align: center;
  color: rgb(39, 24, 122);
  font-size: 18px;
  font-weight: 900px;
  text-align: center;
}

.MainDiv5 {
  height: auto;
  width: 100vw;
  position: relative;
}

.MainDiv6 {
  border-top: 1px solid rgba(233, 231, 231);
  margin: 0 5vw 0 5vw;
  height: 30vh;
  width: 95vw;
  padding: 2vh 0vw 2vh 0vw;
  display: flex;
  flex-direction: column;
}

.feedBackHeadingDiv {
  text-align: center;
  color: rgb(39, 24, 122);
}

.FeedBackTextDiv {
  width: 90vw;
  color: rgb(131, 131, 131);
  display: flex;
  flex-flow: row wrap;
}

/* Oncursor change background and color */

.FeedBackText {
  border-radius: 6px;
  border: 1px solid rgba(161, 159, 159);
  padding: 5px 5px 5px 5px;
  margin: 5px 5px 5px 5px;
}

.FeedBackText:hover {
  background-color: rgb(39, 24, 122);
  color: white;
  cursor: pointer;
}

.FeedBackTextBox_Div {
  display: flex;
  justify-content: center;
  align-self: center;
}
.FeedBackTextBox {
  width: 90vw;
  height: 20vh;
  margin-top: 1rem;
  border: 1px solid rgb(130, 129, 129);
  border-color: rgba(0, 0, 0, 0.15);
  background-color: white;
  font-size: 16px;
  padding: 0px 0px 0px 5px;
}

.FeedBackTextBox:focus {
  border-color: rgb(39, 24, 122);
  /* box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); */
  padding: 0%;
}

.MainButtonDiv {
  height: 15vh;
  width: 100vw;
  /* background-color: rosybrown; */
  text-align: center;
  color: rgb(39, 24, 122);
  font-size: 12px;
  text-align: center;
  margin: 2vh 0 0vh 0;
}

.submitBtn {
  color: var(--primary);
  background-color: var(--secondary);
  border-radius: 20px;
  padding: 8px;
  width: 22%;
  font-weight: 500;
  font-size: medium;
  width: 300px;
  height: 5vh;
  /* margin-top: 10rem; */
}
