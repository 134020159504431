.tripsMainDiv {
  width: calc(100% - 10vw);
  padding: 5vh 5vw;
  background-color: #f4f4fc;
  font-size: 70%;
  flex-wrap: wrap;
  min-height: 90vh;
}
.tripsLeftDiv {
  width: 100%;
}
.tripsRightDiv {
  width: 100%;
}
.tripsTopLine {
  width: 100%;
  display: flex;
  align-items: center;
}
.tripsHeading {
  font-size: 16px;
  color: var(--primary);
  font-weight: 500;
  text-transform: uppercase;
}
.filterDropdown {
  background-color: rgb(217, 235, 241);
  margin: 0 20px;
  border: none;
  border-radius: 5px;
  font-weight: 500;
}
.filterDropdown:focus {
  box-shadow: none;
  border: none;
}
.tripsCardsDiv {
  width: 100%;
  margin: 20px 0;
}
/* CARD CSS */

.cardMainDiv {
  width: calc(100% - 40px);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
  margin: 0 0 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.cardLeftDiv {
  width: 65%;
}
.cardRightDiv {
  width: 30%;
}
.cardStatus {
  text-transform: uppercase;
  background-color: rgb(217, 235, 241);
  color: var(--primary);
  width: fit-content;
  padding: 5px 10px;
  font-size: 70%;
  border-radius: 20px;
  font-weight: 500;
}
.cardDate {
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0px;
}
.cardPickup {
  width: 100%;
  color: grey;
  margin-bottom: 10px;
  margin-right: 10px;
}
.cardPickup2 {
  width: 100%;
  color: grey;
  display: flex;
  justify-content: flex-end;
}
.statusDot {
  width: 6px;
  height: 6px;
  overflow: hidden;
  border-radius: 50%;
  color: transparent;
  margin: 2px 6px 0 0;
}
.vehicleName {
  color: grey;
  margin: 5px 0 0;
}
.totalBill {
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0px;
}
.buttonsDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.changePaymentBtn {
  padding: 5px;
  background-color: var(--primary);
  border-radius: 3px;
  color: white;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 500;
}
.viewDetailsBtn {
  padding: 5px;
  background-color: var(--secondary);
  border-radius: 3px;
  color: var(--primary);
  text-transform: capitalize;
  margin: 0 0 0 5px;
  cursor: pointer;
  font-weight: 500;
}
