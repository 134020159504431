.becomeADriver {
  width: 100vw;
  background-color: #f6f6fd;
  padding: 0 0 10vh;
}
.topBannerDiv {
  width: 100vw;
  display: flex;
  justify-content: center;
}
.bannerTextDiv {
  width: 40%;
  height: 100%;
  padding: 5% 3%;
}
.bannerHeading {
  font-weight: 400;
  font-size: 3.75rem;
  line-height: 4.375rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: var(--primary);
  margin: 1.25rem 0;
}
.bannerText {
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #8b84c6;
}
.bannerButton {
  background-color: #6fde31;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--primary);
  padding: 0.8rem 1.8rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
}
.bannerButton:focus {
  border: none;
  outline: none;
}
.bannerLink {
  font-style: normal;
  font-weight: bold;
  font-size: 0.9375rem;
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
  margin: 1vh 0px;
}
.bannerImageDiv {
  width: 40%;
  padding: 5% 3%;
}
.bannerImage {
}

.vehicleDivHeading {
  width: 100%;
  color: var(--primary);
  font-style: normal;
  font-weight: 500;
  font-size: 2.375rem;
  line-height: 2.8125rem;
  text-align: center;
  margin-bottom: 2rem;
}
.vehicleDivText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #6f6d88;
  width: 100%;
  text-align: center;
}
.vehicleDiv {
  width: 100vw;
}
.tabsSlider {
  width: 68%;
  margin: 0px auto;
}
.vehicleCard {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.vehicleImage {
}
.vehicleText {
  width: 250px;
  text-align: center;
}
.secondBannerMainDiv {
  width: 70vw;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.08),
    0px 10px 25px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin: 3vh auto;
  padding: 15vh 7.5vw;
  background-color: #ffffff;
}
.secondBannerHeading {
  font-weight: 400;
  font-size: 2.375rem;
  line-height: 3.3125rem;
  color: var(--primary);
  width: 100%;
  text-align: center;
}
.secondBannerText {
  width: 100%;
  color: #8b84c6;
  text-align: center;
  margin: 4vh 0;
  font-size: 14px;
  font-weight: 400;
}
.secondBannerButton {
  background-color: var(--primary);
  color: #e4e2f5;
  margin: 0px auto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: block;
  text-align: center;
  padding: 1rem 2.5rem;
  border: none;
  border-radius: 0.5rem;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0.5rem;
}
.secondBannerButton:focus {
  outline: none;
  border: none;
}
.helpStepsMainDiv {
  width: 70vw;
  margin: 10vh auto;
}
.helpStepsMainHeading {
  width: 100%;
  text-align: center;
  font-style: normal;
  letter-spacing: -0.02em;
  color: var(--primary);
  font-style: normal;
  font-weight: 400;
  font-size: 2.375rem;
  line-height: 2.8125rem;
  text-align: center;
}
.helpStepsCardsDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.helpStepsCard {
  width: 18%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.helpStepsImgDiv {
  width: 100%;
}
.helpStepCardTextDiv {
  width: 100%;
}
.helpStepsCardImg {
}
.helpStepsCardHeading {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  margin: 2vh 0;
  color: #1d184e;
}
.helpStepsCardText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #6f6d88;
}
.helpStepsMainText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #6f6d88;
  width: 100%;
  text-align: center;
  margin: 10px 0px 5vh;
}
.thirdBannerMainDiv {
  width: 100%;
  background: var(--primary);
  height: 100%;
  color: #f4f3fc;
  margin: 20vh 0 10vh;
}
.thirdBannerInnerDiv {
  display: flex;
  width: 100%;
}
.thirdBannerMainHeading {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 2.375rem;
  line-height: 3.3125rem;
  padding: 5vh 0vh;
}
.thirdBannerMainImage {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 5vw 0 0;
}
.thirdBannerTextDiv {
  width: 50%;
  padding: 15vh 0 0;
}
.thirdBannerTextBullet {
  width: 70%;
  margin: 3vh 0;
}
.thirdBannerStepsHeading {
  color: #ada5f3;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  margin: 1vh 0;
}
.thirdBannerStepsText {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3125rem;
}
.earningMainDiv {
  width: 50vw;
  margin: 0px auto;
}
.earningMainHeading {
  font-weight: 300;
  font-size: 2.375rem;
  line-height: 3.3125rem;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-family: Roboto;
  font-style: normal;
  letter-spacing: -0.02em;
  color: var(--primary);
  width: 100%;
  text-align: center;
}
.earningText {
  color: #8b84c6;
  width: 100%;
  text-align: center;
  font-size: 90%;
  font-family: 500;
  margin: 2.5vh 0;
  line-height: 110%;
}
.earningButton {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: block;
  text-align: center;
  color: #e4e2f5;
  background-color: var(--primary);
  padding: 1rem 2.5rem;
  border: none;
  border-radius: 0.5rem;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0.5rem;
  margin: 1vh auto;
}
.earningButton:focus {
  border: none;
  outline: none;
}
.getStartedMainDiv {
  width: 70%;
  margin: 20vh auto;
  background-color: var(--primary);
  height: 23.56rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.getStartedImageDiv {
  width: 45%;
  height: 140%;
  padding: 0% 10% 0 3%;
  display: flex;
  align-items: center;
  background: linear-gradient(221.92deg, #f6bf4f 16.65%, #fdefd1 95.38%);
  transform: rotate(13.19deg);
  float: right;
  margin: -10vh -10vh 0vh;
}
.getStartedTextDiv {
  width: 55%;
  padding: 5%;
}
.getStartedTextHeading {
  color: #f4f3fc;
  font-size: 2.25rem;
  line-height: 3rem;
  margin-top: 0rem;
}
.getStartedTextText {
  color: #d9d6f5;
  font-weight: 300;
  font-size: 15px;
  margin: 4vh 0;
}
.getStartedButton {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: block;
  text-align: center;
  color: var(--primary);
  background-color: #f6bf4f;
  padding: 1rem 2.5rem;
  border: none;
  border-radius: 0.5rem;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0.5rem;
  margin: 1vh 0;
}
.FAQDiv {
  width: 60%;
  margin: 0 auto 0;
}
/* CSS FOR FAQ PAGE */
.FAQPageTopDiv {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: white;
  font-size: 2.5rem;
  margin: 0px 0px 10vh;
}
.FAQPageMainDiv {
  background-color: #f4f4fc;
}
@media screen and (max-width: 1025px) {
  .secondBannerMainDiv {
    padding: 7.5vw;
  }
  .thirdBannerMainDiv {
    margin: 5vh 0 5vh;
  }
  .thirdBannerMainHeading {
    padding: 5vh 0 0;
  }
  .getStartedMainDiv {
    margin: 5vh auto;
  }
}

@media screen and (max-width: 568px) {
  .topBannerDiv {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: flex-start;
    height: auto;
  }
  .bannerImageDiv {
    width: 100%;
    padding: 10%;
  }
  .bannerTextDiv {
    width: 100%;
    padding: 10%;
  }
  .bannerHeading {
    font-size: 2rem;
    line-height: 2.2rem;
  }
  .vehicleDivText {
    width: 90%;
    margin: 0px auto;
  }
  .tabsSlider {
    width: 100vw;
  }
  .secondBannerMainDiv {
    width: 100vw;
    box-shadow: none;
    border-radius: 0px;
    margin: 3vh 0;
    padding: 3vh 5vw;
  }
  /* .secondBannerHeading {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.7rem;
    color: var(--primary);
    width: 100%;
    text-align: center;
  } */
  .vehicleCard {
    width: 150px;
  }
  .vehicleText {
    width: 150px;
  }
  .helpStepsCard {
    width: 80vw;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    margin: 2vh 0;
  }
  .helpStepCardTextDiv {
    width: 75%;
  }
  .helpStepsImgDiv {
    width: 20%;
    display: flex;
    align-items: center;
    margin: auto 0px;
  }
  .helpStepsCardHeading {
    margin: 0px;
  }
  .thirdBannerInnerDiv {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .thirdBannerTextDiv {
    width: 100%;
  }
  .thirdBannerTextBullet {
    width: 60%;
    margin: 0px auto;
  }
  .thirdBannerTextDiv {
    padding: 0px;
  }
  .thirdBannerMainImage {
    width: 100%;
    padding: 0px 15vw;
  }
  .getStartedMainDiv {
    height: auto;
  }
  .getStartedImageDiv {
    width: 100%;
    height: 50%;
    align-items: center;
    justify-content: center;
    transform: none;
    margin: 0;
    padding: 0 25%;
  }
  .getStartedTextDiv {
    width: 100%;
    padding: 5vh 5vw;
  }
  .getStartedTextText {
    margin: 1vh 0;
  }
  .getStartedMainDiv {
    margin: 5vh auto;
  }
  .FAQDiv {
    padding: 0%;
    width: 80%;
  }
}
