.thankyouMainDiv {
  width: calc(100% - 40px);
  padding: 10vh 20px;
}
.thankyouCheck {
  width: 90px;
  height: 90px;
  color: var(--secondary);
  border: 2px solid var(--primary);
  border-radius: 50%;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 20px;
}
.thankyouHeading {
  color: var(--secondary);
  font-weight: 500;
  font-size: 40px;
  text-align: center;
}
.thankyouText {
  font-weight: 450;
  font-size: 3.2vh;
  color: var(--primary);
  text-align: center;
  margin: 10px 0;
}
