/* SECTION 1 */
.s1Md {
  width: calc(100% - 30vw);
  padding: 60px 15vw;
  display: flex;
  justify-content: space-between;
}
.s1CL {
  width: 47%;
  padding: 3% 0 0;
}
.s1CLH {
  color: var(--primary);
  font-size: 55px;
  font-weight: 900;
  line-height: 60px;
}
.s1CLT {
  color: gray;
  font-size: 22px;
  margin: 20px 0 25px;
}
.s1CLBtn {
  background-color: var(--secondary);
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  height: 46px;
  width: 200px;
  cursor: pointer;
  font-weight: 500;
  margin: 20px 0;
}
.s1CLBtn:hover {
  background-color: var(--primary);
  color: var(--secondary);
}
.s1CRImg {
  width: 50%;
}
/* SECTION 2 */
.s2Md {
  width: calc(100% - 30vw);
  padding: 60px 15vw;
  background-color: #ebebf8;
}
.s2H {
  color: var(--primary);
  text-align: center;
  margin: 0 0 8px;
}
.s2T {
  color: gray;
  font-size: 16px;
  margin: 0px 0 40px;
  text-align: center;
}
.s2Cards {
  display: flex;
  justify-content: space-between;
}
.s2Card {
  width: 30%;
  overflow: hidden;
  /* height: 400px; */
  background-color: white;
}
.s2CardImg {
  width: 100%;
}
.s2CardH {
  color: var(--primary);
  font-size: 24px;
  margin: 25px 25px 8px;
}
.s2CardT {
  color: var(--text);
  font-size: 14px;
  margin: 0px 25px 25px;
}
/* SECTION 3 */
.s3Md {
  width: calc(100% - 30vw);
  padding: 60px 15vw;
}
.s3H {
}
.s3Images {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.s3Img {
  width: 10%;
}
/* SECTION 4 */
.s4Md {
  width: calc(100% - 30vw);
  padding: 60px 15vw;
  background-color: var(--primary);
  color: white;
}
.s4H {
  text-align: center;
  margin: 0 0 8px;
}
.s4T {
  font-size: 16px;
  margin: 0px 0 40px;
  text-align: center;
}

.s4Cards {
  display: flex;
  justify-content: space-between;
}
.s4Card {
  width: 48%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.s4CardImg {
  height: 80px;
  margin: 10px auto 40px;
}
.s4CardText {
}
.s4Btn {
  background-color: var(--secondary);
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  height: 44px;
  width: 180px;
  cursor: pointer;
  font-weight: 500;
  margin: 20px auto 10px;
}
.s4Btn:hover {
  background-color: var(--primary);
  color: var(--secondary);
}
/* SECTION 5 */
.s5Md {
  width: calc(100% - 30vw);
  padding: 60px 15vw;
}
.s5H {
  text-align: center;
  margin: 0 0 8px;
  color: var(--primary);
}
.s5T {
  font-size: 16px;
  margin: 20px 0 40px;
  text-align: center;
  color: var(--primary);
}
.s5Cards {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
}
.s5Card {
  width: 30%;
}
.s5CardImg {
  height: 110px;
  width: 110px;
  display: block;
  margin: 0px auto 35px;
}
.s5CardH {
  color: var(--primary);
  font-size: 24px;
  margin: 0px 0px 30px;
  width: 100%;
  text-align: center;
}
.s5CardT {
  color: var(--text);
  font-size: 14px;
  width: 100%;
  margin: 0px;
}
.s5CardBullet {
  width: 100%;
  color: var(--text);
}
li::marker {
  color: var(--primary);
}

/* SECTION 6 */
.s6Md {
  width: calc(100% - 40vw);
  padding: 60px 20vw;
  display: flex;
  justify-content: space-between;
}
.s6L {
  width: 40%;
}
.s6R {
  width: 50%;
  display: flex;
  align-items: center;
}
.s6RSubDiv {
}
.s6RH {
  color: var(--primary);
}
.s6RT {
  color: var(--text);
}
.s6RBtn {
  background-color: var(--secondary);
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  height: 46px;
  width: 200px;
  cursor: pointer;
  font-weight: 500;
  margin: 20px 0;
}
.s6RBtn:hover {
  background-color: var(--primary);
  color: var(--secondary);
}
/* SECTION 7 */
.s7M {
  margin: 60px 15vw;
}
.s7Heading {
  color: var(--primary);
  font-weight: 500;
  font-size: 40px;
}
.s7FAQs {
  margin: 5vh 0 0;
  border: 0.5px solid gray;
}
.s7FAQM {
  border-bottom: 1px solid gray;
  background-color: #f6f6fd;
}
.s7FAQAcc {
  background-color: #f6f6fd !important;
}
.s7FAQSummary {
  background-color: #f6f6fd !important;
}
.s7AccordionQuestion {
  color: var(--primary);
  font-weight: 500;
  font-size: larger;
}
.s7AccordionAnswer {
  color: gray;
  /* font-weight: 500; */
  border-top: 1px solid gray;
}
/* SECTION 8 */
.s8Md {
  margin: 60px 15vw;
  display: flex;
  justify-content: space-between;
}
.s8Card {
  width: 49%;
  background-color: white;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 25px;
  cursor: pointer;
}
.s8CardSubDiv:hover {
  transform: scale(1.02, 1.02);
}
.s8CardSubDiv {
  margin: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s linear;
}
.s8CardName {
  color: var(--primary);
  font-weight: 500;
  margin-top: 10px;
  font-size: 18px;
}
.s8CardImg {
  width: 70px;
  height: 60px;
}

@media screen and (max-width: 1440px) {
  .s1Md {
    width: calc(100% - 10vw);
    padding: 60px 5vw;
    align-items: center;
  }
  .s1CRImg {
    height: 27vw;
  }
  .s2Md {
    width: calc(100% - 10vw);
    padding: 60px 5vw;
  }
  .s2Card {
    width: 32%;
  }
  .s3Md {
    width: calc(100% - 10vw);
    padding: 60px 5vw;
  }
  .s4Md {
    width: calc(100% - 10vw);
    padding: 60px 5vw;
  }
  .s5Md {
    width: calc(100% - 10vw);
    padding: 60px 5vw;
  }
  .s5CardBullet {
    margin: 10px 0px;
  }
  .s6Md {
    width: calc(100% - 30vw);
    padding: 60px 15vw;
  }
  .s7M {
    margin: 60px 5vw;
  }
  .s8Md {
    margin: 60px 5vw;
  }
}

@media screen and (max-width: 600px) {
  .s1Md {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
    flex-wrap: wrap;
  }
  .s1CL {
    width: 100%;
  }
  .s1CLH {
    font-size: 35px;
    line-height: 40px;
  }
  .s1CLT {
    font-size: 16px;
  }
  .s1CRImg {
    height: auto;
    width: 100%;
  }
  .s2Md {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
  }
  .s2Cards {
    flex-wrap: wrap;
  }
  .s2Card {
    width: 100%;
    margin: 10px 0;
  }
  .s3Md {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
  }
  .s3Images {
    flex-wrap: wrap;
  }
  .s3Img {
    margin: 5px 0;
    width: 20.5%;
  }
  .s4Md {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
  }
  .s4Cards {
    flex-wrap: wrap;
  }
  .s4Card {
    width: 100%;
  }
  .s5Md {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
  }
  .s5Cards {
    flex-wrap: wrap;
  }
  .s5Card {
    width: 100%;
  }
  .s5CardBullet {
    margin: 10px 0px;
  }
  .s6Md {
    width: calc(100% - 26vw);
    padding: 30px 13vw;
    flex-wrap: wrap;
  }
  .s6L {
    width: 100%;
    margin-bottom: 15px;
  }
  .s6R {
    width: 100%;
  }
  .s7M {
    margin: 30px 3vw;
  }
  .s8Md {
    margin: 30px 3vw;
    flex-wrap: wrap;
  }
  .s8Card {
    width: 100%;
    margin-bottom: 15px;
  }
}
