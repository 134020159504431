.BookingMainDiv {
  width: 100%;
  background-color: #f1f5ff;
  overflow-y: scroll;
  padding: 0 0 3vh;
}
.backButton {
  height: 30px;
  width: 30px;
  background-color: white;
  color: black;
  font-weight: 500;
  border-radius: 50%;
  position: relative;
  top: 40px;
  left: 15px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookingTopDiv {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.bookingLeftDiv {
  width: 95%;
  padding: 2.5%;
}
.bookingMapDiv {
  width: 100vw;
  height: 50vh;
}
.bookingBottomDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.TopHeading {
  color: var(--primary);
  font-size: 35px;
  font-weight: 600;
  margin: 5px 0 10px;
}
.Level1Heading {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin: 5px 0 8px;
}
.Level2Heading {
  font-size: 1.5rem;
  color: var(--primary);
  margin-bottom: 15px;
  font-weight: 500;
}
.Level3Heading {
  color: #8b84c6;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 3px 0;
}

/* 
SLIDER CSS
*/
.sliderMainDiv {
  width: 100%;
  margin: 10px 0;
  position: relative;
}
.sliderCard {
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sliderTopBar {
  display: flex;
  width: 100%;
}
.sliderTopBarButtons {
  display: flex;
  width: 85%;
  flex-wrap: nowrap;
}
.vehicleButton {
  /* width: 25%; */
  padding: 10px 10px;
  font-weight: 700;
  font-size: 16px;
  background-color: transparent;
}
.sliderDropdownBtn {
  width: 10%;
  position: relative;
  left: 10px;
  top: 5px;
}
.slider3dotsBtn {
  display: flex;
  height: 30px;
  width: 40px;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: white;
  font-size: 5px;
  letter-spacing: 1px;
  border-radius: 5px;
  cursor: pointer;
}
.vehicleDropdown {
  width: calc(94% - 20px);
  position: absolute;
  z-index: 1;
  background-color: white;
  top: 80px;
  border: 1px solid var(--primary);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.vehicleDropdownCard {
  width: 41%;
  height: fit-content;
  border: 3px solid white;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 15px;
}
.sliderContentDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.vehiclesName {
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 24px;
  font-weight: 500;
  color: var(--primary);
  margin: 5px;
}
.vehiclesName2 {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: var(--primary);
  margin: 5px;
}
.vehiclesCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.vehiclesDimentions {
  font-size: medium;
  text-align: center;
  color: gray;
}
.vehiclesDimentions2 {
  text-align: center;
  color: gray;
  font-size: 11px;
  margin-bottom: 10px;
}
.vehiclesSlider {
}
.sliderWrapper {
  width: 75%;
}
.sliderButtonWrapper {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sliderBackForward {
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
}

.map_input {
  position: relative;
}
.direction {
  position: absolute;
  left: 21px;
  z-index: 999;
  width: 1px;
  border-right: 2px dashed var(--primary);
  height: 74px;
  top: 28px;
}
.direction:before {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid var(--primary);
  top: -4px;
  left: -5px;
  background-color: #fff;
}
.direction:after {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  border: 1px solid var(--primary);
  bottom: -4px;
  left: -5px;
  background-color: #fff;
}
.direction:before {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid var(--primary);
  top: -4px;
  left: -5px;
  background-color: #fff;
}
/* places AUTO COMP */
.mapPlacesMainDiv {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin: 10px 0 25px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
}
.placesMainDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0 10px;
  position: relative;
  background-color: #ffffff;
}
.dottedLine {
  position: absolute;
  top: 25px;
  left: 5%;
  z-index: 1;
  color: transparent;
  border-left: 2px dashed var(--primary);
  height: 70px;
}
.PickupOverlapingDiv {
  width: 10%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 50%;
  z-index: 5;
  background-color: inherit;
}
.DropoffOverlapingDiv {
  width: 10%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 50%;
  z-index: 5;
  background-color: inherit;
  margin-top: 22px;
}
.StopOverlapingDiv {
  width: 10%;
  height: 100%;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.StopsCircle {
  width: 10px;
  height: 10px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  color: transparent;
  z-index: 5;
  background-color: white;
}
.PlacesCircle {
  width: 10px;
  height: 10px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  color: transparent;
}
.stopBtn {
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 350;
  font-size: medium;
  background-color: transparent;
}
.stopBtn:hover {
  color: var(--secondary);
}
.stopPlusIcon {
  height: fit-content;
  border-radius: 50%;
  font-size: 20px;
  margin: 5px;
}
.PlacesAutocomplete {
  width: 100%;
  margin: 0px auto;
  padding: 5px 0px;
  position: relative;
  height: 50px;
  margin-bottom: 15px;
}
.PlacesAutocomplete2 {
  margin-top: 15px;
  width: 100%;
  margin: 0px auto;
  padding: 5px 0px;
  position: relative;
  height: 50px;
}
.inputLine {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  border: 1px solid #ced4da;
  /* border: 1px solid var(--primary); */
  background-color: white;
  padding: 0%;
  height: 50px;
}
.paperPlaneImg {
  position: absolute;
  height: 20px;
  top: 15px;
  right: 10px;
}
.pickup_InputTag {
  border-color: transparent;
  width: 95%;
  height: 100%;
  background-color: transparent;
  color: gray;
  background: #fff url("../assets/images/paper_plane.png");
  background-repeat: no-repeat;
  background-position: 97%;
}
.pickup_InputTag:focus {
  box-shadow: none;
  border: none;
  outline: none;
}
.pickupInputTag {
  border-color: transparent;
  width: 90%;
  height: 100%;
  background-color: transparent;
  color: gray;
  font-size: 16px;
}
.pickupInputTag:focus {
  box-shadow: none;
  border: none;
  outline: none;
}
.suggestionsDiv {
  position: absolute;
  z-index: 999;
  width: 100%;
}
.suggestionsLine {
  width: calc(95% - 4px);
  margin: 0px 0px 0px auto;
  padding: 2px;
  background-color: white;
}
.stopMainDiv {
  width: 93%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  padding: 1.5%;
}
.stopDeleteButton {
  width: 20%;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stopDeleteButton:hover {
  cursor: pointer;
  font-size: 18px;
  color: rgb(189, 2, 2);
}
.addAStopButtonDiv {
  width: 100%;
  margin: 0% 0 -1% auto;
  display: flex;
  justify-content: flex-end;
}
.stopText {
  width: 71%;
  display: flex;
  align-items: center;
  color: gray;
  padding: 0 2%;
}

/* LOAD SIZE CSS */
.loadSizeMainDiv {
  width: 100%;
  margin: 10px 0;
}
.loadSizeBtnsGrp {
  width: 100%;
  display: flex;
}
.loadSizeBtn {
  border: 1px solid var(--primary);
  color: var(--primary);
  width: 111.95px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #fff;
  margin: 0 5px 5px 0;
}
.firstScreenTotal {
  color: var(--primary);
  font-size: 30px;
  font-weight: 700;
  margin: 15px 0;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid var(--primary);
  border-top: 2px solid var(--primary);
}

/* COUNTER CSS */
.counterMainDiv {
  margin: 20px 0;
}
.counterStrip {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.BookingButton,
.ScheduleButton {
  align-self: center;
}
.counterInput {
  width: calc(64% - 20px);
  height: 30px;
  border: 1px solid #e7e7ed;
  outline: none;
  margin-right: 0%;
  padding: 10px;
  text-align: center;
  font-size: 24px;
  color: var(--primary);
}
.counterInput:active {
  outline: none;
}
.counterButtons {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  background-color: var(--primary);
  cursor: pointer;
}
.counterButtons:hover {
  background-color: var(--secondary);
}
.dropdownMainDiv {
  width: 94%;
  border: none;
  border-bottom: 3px solid var(--primary);
  margin: 10px auto;
  outline: none;
  box-shadow: none;
  color: var(--primary);
  font-weight: 500;
  font-size: 20px;
  height: 60px;
  -webkit-appearance: none;
  /* background-color: #f6f6fd; */
  position: relative;
}
.dropdownTextDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
}
.DDOptions {
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 5000;
  border: 1px solid black;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
}
.DDOption {
  width: calc(100% - 20px);
  padding: 3px 10px;
  cursor: pointer;
}
.DDOption:hover {
  color: black;
}
/* 
Upload icon
*/
.uploadImageMainDiv {
  width: 100%;
}
.uploadImageCustom {
  color: var(--primary);
}
.UploadDiv {
  width: calc(100% - 20px);
  margin: 30px 0 20px;
  display: block;
  padding: 14px 10px;
  background: #fff;
  color: var(--primary);
  font-size: 1em;
  transition: all 0.4s;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
}
.uploadImageIcon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookingFilesMainDiv {
  width: 100%;
  margin: 10px 0;
  color: #8b84c6;
}
.bookingFilesList {
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
}
.fileNameImgDiv {
  display: flex;
  align-items: center;
}
.bookingFileImg {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}
.videoBottomText {
  margin: 15px 0 10px;
  font-weight: 500;
  line-height: 24px;
  font-size: 24px;
  display: inline-block;
}
.uploadMainDiv {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
}
.uploadMainDiv:hover {
  background-color: #424058;
}
.uploadImageInput {
  display: none;
}
.container {
  height: 100vh;
  width: 100%;
  padding-bottom: 70px;
  background-color: #1155ff;
}
.containerBusiness {
  padding-bottom: 220px;
  background-color: #f1f5ff;
}
.containerDelivery {
  background-color: #f1f5ff;
}

.containerIndex {
  background-color: #f1f5ff;
}

.cameraSpan {
  color: var(--primary);
  padding: 7px;
  font-size: 20px;
  margin: 10px;
  display: flex;
  justify-content: center;
}
.bookingButtonsGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.roundBtn {
  background-color: var(--secondary);
  color: var(--primary);
  margin: auto;
  width: 160px;
  height: 40px;
  font-weight: bold;
  border-radius: 25px;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.roundBtn:hover {
  color: white;
  background-color: var(--primary);
}
.roundBtn3 {
  background-color: var(--secondary);
  color: var(--primary);
  margin: auto;
  width: 160px;
  height: 40px;
  font-weight: bold;
  border-radius: 25px;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.roundBtn2 {
  color: var(--primary);
  height: 40px;
  width: 120px;
  font-weight: 500;
  border-radius: 25px;
  font-size: larger;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.roundBtn2:hover {
  color: #8b84c6;
}
.roundBtn4 {
  background-color: var(--secondary);
  color: var(--primary);
  margin: auto;
  width: 120px;
  height: 40px;
  font-weight: bold;
  border-radius: 20px;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.roundBtn4:hover {
  color: white;
  background-color: var(--primary);
}

.roundBtns {
  background-color: var(--secondary);
  color: var(--primary);
  margin-top: 15;
  width: 150px;
  height: 40px;
  font-weight: bold;
  border-radius: 25px;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.roundBtns:hover {
  color: white;
  background-color: var(--primary);
}
/* 
Tooltip
*/
.tooltipMainDiv {
  min-width: 220px;
  max-height: 300px;
  border-radius: 15px;
  padding: 20px;
  background-color: var(--primary);
}
.tooltipBullet {
  display: flex;
  width: 100%;
  margin: 15px 0;
}
.tooltipDotBox {
  width: 10%;
  display: flex;
}
.tooltipDot {
  width: 10px;
  height: 10px;
  background-color: var(--secondary);
  color: transparent;
  border-radius: 50%;
  margin-top: 10px;
}
.tooltipText {
  width: 90%;
  color: white;
  font-size: 16px;
}
/*
MAP
*/

.mapDiv {
  width: 100%;
  height: 100%;
}
/* .distanceTimeDiv {
  width: 100%;
  text-align: center;
} */
.appCardMainDIv {
  width: 100%;
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 0 40px;
}
.appCard {
  width: calc(95% - 4vw);
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px 5px rgba(68, 41, 41, 0.2);
  border-radius: 10px;
  padding: 20px 2vw;
  height: 170px;
  transition: width 0.5s, height 0.5s;
}
.appCard:hover {
  width: 98%;
  height: 175px;
  transition: width 0.5s, height 0.5s;
}

.appCardCardHeading {
  font-size: 27px;
  color: var(--primary);
  font-weight: 500;
  line-height: 30px;
}
.slider3dotsBtn:hover {
  background-color: var(--secondary);
}

/* 
CONFIRM DETAILS
*/
.confirmDetailsMainDiv {
  padding: 5vh 10px;
  display: flex;
  flex-wrap: wrap;
}
.confirmDetailsLine {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.confirmDetailsBox {
  width: 48%;
  margin: 5px auto;
  border-right: none;
  border-bottom: 1px solid grey;
  border-radius: 30px;
  min-height: 80px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
}
.confirmDetailsBox:hover {
  font-weight: bold;
  width: 49%;
  margin: 5px 0.5%;
  transition-property: width margin;
  transition-duration: 0.3s;
}
.confirmDetailsContent {
  /* margin-left: 25%; */
  margin: 10px;
}
.confirmDetailsHeading {
  width: calc(100% - 40px);
  background-color: var(--primary);
  color: white;
  display: flex;
  border-radius: 30px 30px 0 0;
  padding: 5px 20px;
}
.confirmIcon {
  color: white;
  margin: 0 5px 0 0;
  font-size: larger;
}
.confirmIconImg {
  width: 30px;
  margin: 0 5px 0 0;
}
.confirmDetailsText {
  font-size: 110%;
  color: var(--primary);
  margin: 5px 10px;
}
/* 
RECIPENT INFO
*/
.recipendInfoMainDiv {
  width: calc(100% - 20px);
  padding: 5vh 10px;
  border-bottom: 1px solid silver;
}
.RecipentHeadingText {
  width: 100%;
  color: grey;
  font-size: larger;
  font-weight: 500;
}
.recipentInfoCardsDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.recipentInfoCardBadge {
  background-color: var(--primary);
  color: white;
  font-size: larger;
  font-weight: 500;
  width: 110px;
  height: 40px;
  border-radius: 20px;
  position: relative;
  left: -35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recipentInfoCardMainDiv {
  width: calc(100% - 40px);
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 0 20px 30px 20px;
  background-color: #fdfdfd;
  margin: 20px 10px;
}
.recipentInfoCardTopLine {
  display: flex;
  width: 100%;
}
.recipentInfoCardName {
  margin: 15px 0;
  width: 100%;
  display: flex;
  align-items: center;
}
.recipentInfoCardIcon {
  color: var(--primary);
  margin: 0 20px 0 0;
  font-size: 20px;
}
.recipentInfoCardInput {
  width: 90%;
  border: none;
  outline: none;
  font-size: larger;
  background-color: #fdfdfd;
}
.recipentInfoCardInput:active {
  border: none;
  outline: none;
}
.recipentInfoCardInstriction {
  min-height: 30px;
  width: calc(100% - 20px);
  border: 1px solid gray;
  background-color: white;
  outline: none;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
/* 
CALANDER
*/
.calanderMainDiv {
  width: 100%;
  margin: 10px 0;
}
.calanderConponent {
  min-width: 100% !important;
  width: 100%;
}
.CalanderModalBottomBtns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* 
TOTAL CONFIRM
*/
.confirmBottomDiv {
  width: (100% - 20px);
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.priceDiv {
  margin: 20px auto;
  width: calc(100% - 30px);
  background-color: rgb(240, 240, 240);
  /* color: rgb(112, 104, 104); */
  color: black;
  padding: 15px;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 500;
}
.priceDivTop {
  width: 100%;
  padding: 10px 0 20px;
  border-bottom: 1px solid rgb(112, 104, 104);
}
.priceDivMid {
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid rgb(173, 173, 173);
}
.priceBreakdownLine {
  display: flex;
  justify-content: space-between;
}
.priceDivBottom {
  font-weight: 700;
  color: black;
  width: 100%;
  padding: 20px 0 10px;
}
.confirmPickupButton {
  background-color: var(--primary);
  width: 130px;
  height: 40px;
  color: white;
  font-weight: bold;
  margin-right: 5px;
  border-radius: 20px;
}
.editTripbtn {
  border-radius: 20px;
  width: 130px;
  height: 40px;
  color: var(--primary);
  border: 1px solid var(--primary);
  font-weight: bold;
}
.priceBreakdownCloseButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.confirmBottomRightDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.totalConfirmMainDIv {
  width: calc(100% - 40px);
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  margin: 20px 0;
  border-radius: 15px;
  padding: 20px;
}
.totalHeading {
  width: 100%;
  border-bottom: 1px solid var(--secondary);
  font-weight: 500;
  font-size: 44px;
  color: var(--primary);
  padding: 10px 0 20px;
}
.totalText {
  width: 100%;
  font-weight: 500;
  font-size: 35px;
  padding: 15px 0;
  color: gray;
}

/* 
Third Screen
*/

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  background-color: white;
  border-radius: 10px;
  padding: 40px 25px;
  width: calc(90vw - 50px);
  margin: 30px auto 0;
}
.costBreakdownTopText {
  margin: 0%;
  padding: 0%;
  font-weight: 150;
  color: gray;
  line-height: 13px;
  font-family: sans-serif;
}
.costBreakdownHeading {
  font-size: 28px;
  color: var(--primary);
  margin: 0px;
  padding: 0%;
  line-height: 32px;
}
.cardWindowMainDiv {
  width: calc(90% - 24px);
  margin: 10vh auto;
  background-color: white;
  border-radius: 10px;
  padding: 30px 12px;
}
.cardPaymentProcess {
  color: #8b84c6;
  text-transform: uppercase;
  font-weight: 200;
  /* font-size: 120%; */
}
.checkoutInfo {
  color: var(--primary);
  text-transform: capitalize;
  font-size: 150%;
  font-weight: 400;
}
.cardWindowInfoText {
  font-size: 90%;
  margin: 20px 0;
  color: gray;
}
.payNowButtons {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
}
.accountDetailsBoxInputBox {
  width: calc(100% - 10px);
  padding: 10px 5px;
  margin: 10px 0px;
  background-color: white;
  color: gray;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
  position: relative;
}
.accountDetailsBoxInputBoxLabel {
  font-weight: 300;
  width: 300px !important;
}
fieldset {
  border-width: 0 !important;
}
.accountDetailsBoxInputBoxInput {
  width: 87%;
  border: none;
  outline: none;
}
.accountDetailsBoxInputBoxInput:focus {
  outline: none;
  border: none;
}
.accountDetailsBoxInputBoxEditIcon {
  margin: 0px 0 0 5px;
  cursor: pointer;
}
.cardInput {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cardNumberInput {
  margin: 0px 5px;
  border: 0px;
  outline: none;
}
.cardNumberInput:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.cardMonthYearInput {
  width: 30px;
  margin: 0px 5px;
  border: none;
  outline: none;
}
.cardMonthYearInput:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.cardCVC {
  width: 40px;
  margin: 0px 0px 0px 5px;
  border: none;
  outline: none;
}
.cardCVC:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.confirmationLine {
  color: #8b84c6;
  margin: 30px 0 10px 0;
}
.cancelButton {
  border-radius: 6px;
  margin: 0px 5px;
  padding: 8px 10px;
}
.payNowButton {
  background-color: var(--primary);
  border-radius: 6px;
  color: white;
  margin: 0px 5px;
  padding: 8px 10px;
}
.saveAndPayNowButton {
  background-color: #fcbc38;
  border-radius: 6px;
  color: var(--primary);
  margin: 0px 5px;
  padding: 8px 10px;
}

/* 
For Confirmation Page
*/
.priceDivConfirmation {
  width: 320px;
  max-height: 300px;
  background-color: var(--primary);
  color: white;
  padding: 15px;
  border-radius: 15px;
  font-size: 18px;
  overflow-y: auto;
}
.priceDivTopConfirmation {
  width: 100%;
  padding: 10px 0 20px;
  border-bottom: 1px solid var(--secondary);
}
.priceBreakdownLineConfirmation {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.action_area {
  text-align: center;
  padding: 15px 0;
}
.greenBBtn {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  background-color: var(--secondary);
  color: var(--primary);
  width: calc(45% - 0.75rem);
  border-radius: 25px;
  font-size: 17px;
  font-weight: 600;
}
.outlinedBBtn {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-color: var(--primary);
  color: var(--primary);
  width: calc(45% - 0.75rem);
  border-radius: 25px;
  font-size: 17px;
  font-weight: 600;
}

.vehiclesImageImg {
  width: 100%;
}

.lodingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(245, 246, 250, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadingDiv {
  width: 200px;
  height: 200px;
}
.loadingFrog {
  width: 120px;
  height: 120px;
  margin: 0 40px;
}

@media screen and (max-width: 600px) {
  .vehicleDropdown {
    height: 260px;
    overflow-y: auto;
  }
}

::-webkit-file-upload-button {
  color: var(--primary);
  border: 2px solid var(--primary) !important;
  background: transparent;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500px !important;
  margin-right: 15px;
}
