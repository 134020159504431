.homeMainDiv {
  width: calc(100% - 20px);
  padding: 10px 10px 40px;
}
.cardsArea {
  width: calc(100%-20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cardMainDiv {
  background-color: white;
  border-radius: 20px;
  height: 45vw;
  width: 47vw;
  margin: 10px 0;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.cardMainDiv:hover {
  background-color: var(--primary);
  color: white;
}
.sliderMainDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.textDiv {
  border-radius: 20px;
  box-shadow: 0px 5px #ededed;
  width: calc(45% - 15px);
  height: 30%;
  background-color: white;
  color: var(--primary);
  padding: 15px 5% 15px 15px;
  font-size: 24px;
  line-height: 25px;
  font-weight: 350;
}
.sliderImage {
  height: 70%;
  float: right;
}
.DotGroup {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: -20px 0 0;
}
.dotcss {
  z-index: 5;
  font-size: 10px;
  margin: 5px 0px;
}
@media screen and (max-width: 1100px) {
  .cardMainDiv {
    margin: 1vh 0;
  }
  .cardImage {
    margin: 7vw auto 10px;
    height: 23vw;
  }
  .cardTitle {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 250%;
  }
}
@media screen and (max-width: 600px) {
  .cardMainDiv {
    width: 44vw;
  }
  .cardImage {
    height: 20vw;
  }
  .cardTitle {
    font-size: 120%;
  }
  .sliderMainDiv {
    display: block;
    width: 100%;
  }
  .textDiv {
    border-radius: 20px;
    box-shadow: 0px 5px #ededed;
    width: 45vw;
    height: 13vh;
    background-color: white;
    color: var(--primary);
    padding: 15px 25% 15px 15px;
    font-size: 24px;
    line-height: 27px;
    font-weight: 350;
  }
  .sliderImage {
    position: relative;
    height: 90px;
    top: -105px;
  }
}
