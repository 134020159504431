.termsMainDiv {
  padding: 7.5vh 5vw;
}
.mainHeading {
  font-size: 30px;
  font-weight: 700;
}
.termDate {
  font-size: large;
  font-style: italic;
  font-weight: 500;
}
.paragraph {
  margin: 20px auto;
}
.indexL1 {
  display: flex;
  margin: 20px 0 20px 2vw;
}
.indexL1index {
  font-weight: 500;
  width: 4vw;
}
.indexL1text {
  width: 84vw;
  text-decoration: underline;
  color: blue;
}
.indexL2 {
  display: flex;
  margin: 5px 0 0 6vw;
}
.indexL2index {
  width: 3vw;
  font-weight: 500;
}
.indexL2text {
  width: 80vw;
  text-decoration: underline;
  color: blue;
}
.bullet1 {
  display: flex;
  margin: 40px 0 40px 2vw;
}
.bullet1Index {
  width: 4vw;
}
.bullet1text {
  font-weight: 700;
  font-size: large;
  width: 84vw;
}
.bullet2 {
  display: flex;
  margin: 20px 0 20px 8vw;
}
.bullet2Index {
  width: 4vw;
}
.bullet2text {
  width: 78vw;
  font-weight: 700;
}
.bullet3 {
  display: flex;
  margin: 10px 0 10px 14vw;
  font-weight: 400;
}
.bullet3Index {
  width: 4vw;
}
.bullet3Text {
  width: 72vw;
}
.bullet3Note {
  margin: 10px 0 10px 18vw;
}
.bullet4 {
  display: flex;
  font-weight: 400;
  margin: 10px 0 10px 20vw;
}
.bullet4Index {
  width: 4vw;
}
.bullet4text {
  width: 65vw;
}
.bullet4Note {
  margin: 10px 0 10px 24vw;
}
.bullet5 {
  display: flex;
  margin: 10px 0 10px 30vw;
}
.bullet5Index {
  width: 4vw;
}
.bullet5text {
  width: 55vw;
}
.bulletAlphabel {
  display: flex;
  margin: 10px 0 10px 2vw;
}
.bulletAlphabelIndex {
  width: 5vw;
}
.bulletAlphabelText {
  width: 82vw;
}
