.tripsMainDiv {
  width: calc(100% - 10vw);
  padding: 5vh 5vw;
  display: flex;
  background-color: #f4f4fc;
  justify-content: space-between;
  font-size: 70%;
}
.tripsLeftDiv {
  width: 23%;
}
.tripsRightDiv {
  width: 75%;
}
.tripsTopLine {
  width: 100%;
  display: flex;
  align-items: center;
}
.backButton {
  margin: 0 5px 0 0;
}
.tripsHeading {
  font-size: 16px;
  color: var(--primary);
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}
.TripCard {
  width: 600px;
  box-shadow: 0 4px 3px 3px rgba(0, 0, 0, 0.1);
  padding: 0%;
  margin: 20px 0px;
  border-radius: 0px 0px 10px 10px;
}
.TripMap {
  width: 100%;
  overflow: hidden;
}
.TripCardWhite {
  width: 92%;
  height: fit-content;
  background-color: white;
  padding: 4%;
  border-radius: 0px 0px 10px 10px;
}
.TripCardFirstLine {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 30px;
}
.TripCardFirstLineBox {
  width: 22.5%;
}
.TripCardFirstLineBoxLabel {
  width: 100%;
  color: gray;
}
.TripCardFirstLineBoxLabelIcon {
  margin-right: 5px;
}
.TripCardFirstLineBoxLabelText {
  color: gray;
}
.TripCardFirstLineBoxData {
  width: 100%;
  font-weight: 700;
}
.TripCardSecondLine {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}
.TripCardSecondLineBox {
  width: 47.5%;
}
.TripCardSecondLineBoxLabel {
  width: 100%;
  color: gray;
}
.TripCardSecondLineBoxLabelIcon {
  margin-right: 5px;
}
.TripCardSecondLineBoxLabelText {
}
.TripCardSecondLineBoxData {
  width: 100%;
  font-weight: 700;
}
.TripCardThirdLine {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(209, 209, 209);
}
.TripCardThirdLineAddress {
  display: flex;
  color: gray;
  margin: 5px 0;
}
.TripCardThirdLineAddressIcon {
  margin-right: 5px;
}
.TripCardThirdLineAddressLabel {
  margin-right: 5px;
}
.TripCardThirdLineAddressText {
  color: black;
  font-weight: 500;
}
.TripCardFourthLine {
  display: flex;
  width: 100%;
  margin: 20px 0 0px;
}

.TripCardFourthLineFileOne {
  display: flex;
  justify-content: space between;
  flex-wrap: wrap;
  margin-left: 1rem;
}

.TripCardFourthLineFileInner {
  display: inline block;
  padding: 0.5em;
  display: block;
  width: calc(40% - 1em);
  box-sizing: border-box;
  margin-left: 1.5em;
}

.TripCardFourthLineFileInner img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.TripCardFourthLineBox {
  width: 25%;
}
.TripCardFourthLineBoxLabel {
  width: 100%;
  color: grey;
}
.TripCardFourthLineBoxLabelIcon {
  margin-right: 5px;
}
.TripCardFourthLineBoxData {
  font-weight: 700;
  color: black;
}
.cancelPickup {
  padding: 10px 30px;
  color: rgb(206, 0, 0);
  background-color: rgb(255, 202, 202);
  border-radius: 7px;
  margin: 20px 20px 20px 0;
  outline: none;
  font-weight: 700;
  font-size: 110%;
}
.EditTripBtn {
  padding: 10px 30px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 247, 133);
  border-radius: 7px;
  margin: 20px 20px 20px 0;
  outline: none;
  font-weight: 700;
  font-size: 110%;
}
@media screen and (max-width: 770px) {
  .tripsMainDiv {
    flex-wrap: wrap;
  }
  .tripsLeftDiv {
    width: 100%;
  }
  .tripsRightDiv {
    width: 100%;
  }
  .TripCard {
    width: 90vw;
    margin: 20px auto;
  }
}
