.mainDiv {
  width: 100%;
}
.cardMainDiv {
  background-color: #fff;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 00.1);
  width: calc(85% - 40px);
  margin: 10% auto;
  padding: 20px;
  border-radius: 20px;
}
.headingMainDiv {
  color: var(--primary);
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin: 10px 0;
}
.generalText {
  color: gray;
  margin: 0 0 10px;
}
.BtnStyle {
  height: 45px;
  width: 200px;
  border-radius: 22px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary);
  color: var(--primary);
  margin: 20px auto;
}
.BtnStyle:hover {
  background-color: var(--primary);
  color: #fff;
}
.bottomText {
  font-weight: 500;
  color: rgb(177, 177, 177);
}
.checkBox {
  border-radius: 3px;
  margin: 0 3px 3px 0;
}
.highlightedText {
  color: aqua;
  text-decoration: underline;
  cursor: pointer;
}
