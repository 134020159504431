.fullPage {
  width: 100%;
  min-height: 100%;
  background-color: #f6f6fd;
  position: static;
  overflow: auto;
}
.phoneCarousal {
  width: 100%;
}
.sliderMainDiv {
  width: 100%;
  background-image: url("../assets/img/worldMap.jpg");
  background-size: 100vw 50%;
  background-repeat: no-repeat;
  padding: 5vh 0 0;
  display: flex;
  flex-direction: row;
}
.sliderFlexDiv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.sliderRightText {
  width: 40%;
  height: 50%;
  margin: 0%;
}
.sliderLeftText {
  width: 40%;
  height: 50%;
  margin: 0px;
}
.phoneImage {
  width: 20%;
  margin: 0px;
  padding: 0%;
}
.rightHeading {
  font-weight: 400;
  font-size: 3.75rem;
  line-height: 4.375rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: var(--primary);
  width: 50%;
  float: right;
  margin: 5% 5% 5%;
}
.leftTextMainDiv {
  width: 50%;
  float: left;
  margin: 5% 5% 5%;
  line-height: 20px;
  color: #6f6d88;
  font-size: 14px;
}
.leftTextStepNo {
  width: 100%;
}
.leftTextHeading {
  color: #1d184e;
  font-size: 1.5rem;
  font-weight: bolder;
  margin: 7% 0;
}
.sliderControls {
  width: 20%;
  margin: 0px auto 20px;
  display: flex;
  justify-content: space-between;
}
.sliderControlButton {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: #6f6d88;
  border: 2px solid #6f6d88;
}
.sliderControlButton:focus {
  outline: none;
}
.DotGroup {
  width: 50%;
  height: 30px;
  display: flex;
  justify-content: space-around;
  font-size: 9px;
  padding: 0px;
  margin: 0px;
  align-items: center;
  color: #6f6d88;
}
.dotcss:focus {
  border: none;
  outline: none;
}
.FAQDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.FAQBox {
  background: #ffffff;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.08),
    0px 10px 25px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  width: 66%;
  padding: 7%;
}
.FAQHeading {
  font-size: 2rem;
  line-height: 30px;
  width: 100%;
  text-align: center;
}
.accordionMainDiv {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  margin: 2vw 0;
  color: #1d184e;
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid grey;
  box-shadow: none !important;
  border-radius: 0% !important;
  /* .MuiPaper-elevation1 */
}
.FAQQuestionText {
  color: #1d184e;
  font-weight: 700;
}
.FAQQuestionText:hover {
  text-decoration: #1d184e;
}
.FAQAnswerText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #6f6d88;
  padding: 0px 5% 0 0;
}
.endButtonDiv {
  width: 80%;
  background-color: var(--primary);
  margin: 10%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.endButtonTxt {
  font-size: 1.5rem;
  color: #f6f6fd;
  cursor: pointer;
}
.endButtonTxt:hover {
  text-decoration: #f6f6fd;
}
.endBtnLogo {
  border-radius: 50%;
  width: 7vw;
  height: 7vw;
  background-color: #f6f6fd;
  color: var(--primary);
  margin: 0.25vw 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 100;
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .rightHeading {
    font-size: 25px;
    line-height: 28px;
    width: 70%;
  }
  .leftTextHeading {
    font-size: 11px;
    line-height: 13px;
  }
  .leftTextMainDiv {
    width: 75%;
  }
  .leftTextText {
    font-size: 9px;
    line-height: 11px;
  }
  .sliderControls {
    margin: 4vh auto;
    width: 40%;
  }
  .phoneImage {
    margin: 0 0 -1vh 0;
  }
  .FAQBox {
    width: 100%;
    border-radius: 0%;
  }
  .endBtnLogo {
    font-size: 1.5rem;
  }
}
