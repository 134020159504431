.S1_Md {
  padding: 50px 15vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.S1_L {
  width: 47%;
}
.S1_H {
  color: var(--primary);
  font-size: 60px;
  font-weight: 800;
}
.S2_T {
  color: var(--text);
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: normal;
}
.S1_Btn {
  background-color: var(--secondary);
  color: var(--primary);
  border-radius: 25px;
  width: 210px;
  font-weight: 500;
  height: 44px;
}
.S1_Btn:hover {
  background-color: var(--primary);
  color: var(--secondary);
}
.S1_R {
  width: 47%;
}
/* SECTION 2 */

.S2_Md {
  padding: 50px 15vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg);
}
.S2_Card_Md {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 30%;
}
.S2_Img {
  height: 80px;
}
.S2_Title {
  width: 100%;
  color: var(--primary);
  margin: 20px 0;
  text-align: center;
  text-transform: capitalize;
}
.S2_Description {
  width: 100%;
  color: var(--text);
  margin-bottom: 20px;
  text-align: center;
}
/* SECTION 3 */

.S3_Md {
  padding: 50px 15vw;
}
.S3_Heading {
  color: var(--primary);
  text-align: center;
  margin-bottom: 45px;
}
.S3_Cards {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.S3_Card_Md {
  width: 400px;
  height: 400px;
  background-color: white;
  overflow: hidden;
  position: relative;
  margin: 0 10px 20px 10px;
}
.S3_quotation {
  height: 25px;
  margin: 15px 30px;
}
.S3_description {
  color: #404040;
  font-style: italic;
  font-size: 15px;
  margin: 15px 30px;
  font-weight: 300;
  text-align: center;
  line-height: 28px;
}
.S3Circle {
  background-color: var(--primary);
  width: 450px;
  height: 450px;
  border-radius: 50%;
  position: absolute;
  top: 290px;
  left: -25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.S3_Card_img {
  height: 95px;
  width: 95px;
  border: 4px solid var(--secondary);
  border-radius: 50%;
  position: absolute;
  top: -30px;
}
.S3_Card_Name {
  width: 100%;
  font-size: 15px;
  color: #fff;
  text-align: center;
  margin-top: 80px;
}
/* SECTION 4 */

.S4_Md {
  padding: 50px 15vw;
}
.S4_Cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.S4_Card_MD {
  width: 31%;
}
.S4_Img {
  width: 100%;
}
/* SECTION 5 */

.S5_Md {
  padding: 50px 15vw;
}
.S5_Heading {
  color: var(--primary);
  font-size: 34px;
  font-weight: 500;
  margin: 20px 0;
  text-align: center;
}
.S5_CardsDiv {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.S5_CardMD {
  width: 31%;
  background-color: white;
  border-radius: 10px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 10px;
}
.S5_CardTitle {
  color: var(--primary);
  font-weight: 500;
  font-size: 16px;
}
.S5_Image {
  height: 80px;
  width: 80px;
}

@media screen and (max-width: 1920px) {
  .S1_Md {
    padding: 50px 5vw;
  }
  .S2_Md {
    padding: 50px 5vw;
  }
  .S3_Md {
    padding: 50px 5vw 30px;
  }
  .S4_Md {
    padding: 50px 5vw 30px;
  }
  .S5_Md {
    padding: 50px 5vw;
  }
}

@media screen and (max-width: 1440px) {
  .S3_Card_Md {
    width: 340px;
    height: 340px;
  }
  .S3Circle {
    width: 400px;
    height: 400px;
    top: 240px;
    left: -30px;
  }
  .S3_Card_img {
    height: 70px;
    width: 70px;
    top: -23px;
  }
  .S3_description {
    margin: 0px 30px;
  }
  .S3_Card_Name {
    margin-top: 60px;
  }
}

@media screen and (max-width: 1080px) {
  .S1_H {
    font-size: 30px;
    font-weight: 600;
  }
  .S3_Card_Md {
    width: 320px;
    height: 360px;
    margin: 20px 0px;
  }
  .S3Circle {
    width: 360px;
    height: 360px;
    top: 260px;
    left: -20px;
  }
  .S3_Card_img {
    height: 70px;
    width: 70px;
    top: -23px;
  }
  .S3_description {
    margin: 0px 30px;
  }
  .S3_Card_Name {
    margin-top: 60px;
  }
}
@media screen and (max-width: 600px) {
  .S1_Md {
    padding: 20px 3vw;
    flex-wrap: wrap;
  }
  .S1_L {
    width: 100%;
    margin-bottom: 20px;
  }
  .S1_R {
    width: 100%;
  }
  .S2_Md {
    padding: 20px 3vw;
    flex-wrap: wrap;
  }
  .S2_Card_Md {
    width: 100%;
    margin-bottom: 20px;
  }
  .S3_Md {
    padding: 20px 3vw 0px;
  }
  .S4_Md {
    padding: 20px 3vw 0px;
  }
  .S5_Md {
    padding: 20px 3vw;
  }
  .S1_H {
    font-size: 30px;
    font-weight: 600;
  }
  .S3_Card_Md {
    width: 94vw;
    height: 120vw;
  }
  .S3Circle {
    width: 120vw;
    height: 120vw;
    top: 98vw;
    left: -13vw;
  }
  .S3_Card_img {
    height: 20vw;
    width: 20vw;
    top: -8vw;
  }
  .S3_description {
    margin: 0px 30px;
    font-size: 6vw;
    line-height: 8vw;
  }
  .S3_Card_Name {
    margin-top: 15vw;
  }
  .S4_Card_MD {
    width: 100%;
    margin-bottom: 20px;
  }
  .S5_CardMD {
    width: 100%;
  }
}
