.RegisterMainDiv {
  width: 100%;
}
.socialMediaLoginButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.googleLoginButton {
  width: 20%;
  font-weight: 700;
  color: white;
  background-color: #f6bf4f;
}
.RegisterMainHeading {
  font-size: 42px;
  color: var(--primary);
  font-weight: 500;
  width: 100%;
  text-align: center;
}
.halfInputleft {
  width: 50%;
  height: 3rem;
  border-bottom: 1px solid grey;
  padding: 0 1% 0 0;
  /* border-right: 1px solid grey; */
  margin: 1rem 0;
  display: flex;
  align-items: center;
}
.fullInputleft {
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid grey;
  padding: 0 1% 0 0;
  /* border-right: 1px solid grey; */
  margin: 1rem 0;
  display: flex;
  align-items: center;
}
.fullInputTextArea {
  width: 100%;
  height: 10rem;
  padding: 0 1% 0 0;
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;
}
.textAreaFieldFull {
  width: 87.5%;
  outline: none;
  border: none;
  padding: 0%;
  height: 8rem;
  resize: none;
}
.textAreaFieldFull:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none;
}
.contactInfo {
  width: 90%;
  margin: 10px auto;
  font-size: 13px;
  text-align: center;
  color: #6f6d88;
}
.inputField {
  width: 75%;
  outline: none;
  border: none;
}
.inputField:focus {
  border: 0px;
}
.inputFieldFull {
  width: 87.5%;
  outline: none;
  border: none;
}
.inputFieldFull:focus {
  border: 0px;
  outline: none;
  box-shadow: none;
}
.textIcons {
  color: rgb(179, 179, 255);
  margin: 0px 3px 0px 0px;
  width: 10%;
}
.crossIcon {
  color: rgb(255, 133, 133);
  margin: 0px 0px 0px 3px;
  width: 10%;
}
.textIconsFull {
  color: rgb(179, 179, 255);
  margin: 0px 3px 0px 0px;
  width: 5%;
}
.crossIconFull {
  color: rgb(255, 133, 133);
  margin: 0px 0px 0px 3px;
  width: 5%;
}
.checkbox_section {
  width: 58%;
  padding: 0px 1%;
}
.submitButton {
  max-width: 30%;
  background-color: #f6bf4f;
  color: var(--primary);
  padding: 2% 5%;
  margin: 0px 5px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}
.submitButton:focus {
  border: none;
  outline: none;
}
.BottomButtons {
  width: 100%;
  margin: 10px auto 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.BottomButtons2 {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forgotPassword {
  color: var(--primary);
  font-weight: 500;
  cursor: pointer;
  font-size: 110%;
  margin: 0 5px;
}
.friendsReferralsText {
  margin: 0px 0 10px;
}
.forgotPassword:hover {
  color: #6f6d88;
}
.halfInput {
  width: 50%;
  height: 3rem;
  border-bottom: 1px solid grey;
  margin: 1rem 0px;
  display: flex;
  align-items: center;
}

.sign_up_form {
  min-height: 5rem;
  background-color: #fffefd;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.06),
    0px 10px 25px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
}
.signup_page {
  padding: 2.5rem 0;
}
.inner_content {
  margin: 0px 30%;
}
.loginButton {
  width: calc(100% - 5rem);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: block;
  text-align: center;
  color: var(--primary);
  background-color: #e4e2f5;
  padding: 1rem 2.5rem;
  border: none;
  border-radius: 0.5rem;
  margin: 0;
  outline: 0;
  cursor: pointer;
}
.dash_with_title {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ada5f5;
  line-height: 0.1em;
  margin: 1.5rem 0;
  font-size: 0.75rem;
  color: var(--primaryDark);
}
.createAccountTxt {
  background-color: white;
  padding: 0px 7px;
}
.MEZTnC {
  color: var(--primaryDark);
  cursor: pointer;
}
.MEZTnC:hover {
  text-decoration: none;
  color: var(--primaryDark);
}
@media screen and (max-width: 780px) {
  .inner_content {
    margin: 0px 15%;
  }
  .RegisterMainHeading {
    font-size: 4vh;
  }
}
