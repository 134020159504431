.signupMainDiv {
  width: calc(100% - 20px);
  padding: 30px 10px;
}
.posterImage {
  margin: 10px auto 30px;
}
.inputBox {
  width: 94%;
  display: flex;
  align-items: center;
  border: 1px solid rgb(192, 192, 192);
  margin: 8px 0;
  background-color: #ffff;
  color: rgb(192, 192, 192);
}
.passwordInput {
  border: none;
  outline: none;
  width: calc(97% - 50px);
  padding: 15px 15px;
  font-size: 100%;
  border: none;
  outline: none;
  color: gray;
}
.mainForm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.posterMainHeading {
  color: var(--primary);
  font-size: 24px;
  font-weight: 500;
  width: 94%;
}
.posterText {
  color: var(--primary);
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.fullInput {
  width: calc(94% - 30px);
  padding: 15px 15px;
  border: 1px solid rgb(192, 192, 192);
  margin: 8px 0;
  font-size: 100%;
}
.fullInput:focus {
  box-shadow: none;
  outline: none;
}
.TnCMainDiv {
  font-size: 90%;
  color: grey;
  margin: 5px 0;
}
.TnCCheckbox {
  border-radius: 3px;
  margin: 0 3px 0 0;
}
.TnCCheckbox:focus {
  box-shadow: none;
}
.TnCCheckbox:hover {
  color: var(--primary);
}
.signupTxtLink {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}
.signupBtnMainDiv {
  width: 100%;
  display: flex;
  margin: 5px 0;
  justify-content: space-between;
  align-items: center;
}
.signupBtn {
  color: var(--primary);
  background-color: var(--secondary);
  border-radius: 50px;
  padding: 11px;
  width: 158px;
  font-weight: 500;
  font-size: larger;
  margin-left: 25%;
}
.signupBtn:hover {
  color: white;
  background-color: var(--primary);
}
.signinRedirect {
  color: rgb(192, 192, 192);
  font-weight: 500;
  font-size: large;
  text-align: center;
}
.submitBtn {
  color: var(--primary);
  background-color: var(--secondary);
  border-radius: 20px;
  height: 40px;
  width: 180px;
  line-height: 40px;
  font-size: large;
  font-weight: bold;
  margin: 10px auto;
}
.signinTxt {
  color: var(--primary);
  cursor: pointer;
  font-weight: 700;
}
.signinTxt:hover {
  color: var(--secondary);
}
/* DROPDOWN */
.dropdownMainDiv {
  width: 94%;
  margin: 10px 0;
  position: relative;
}
.dropdownTitle {
  color: var(--primary);
  font-weight: 700;
  font-size: 16px;
}
.dropdownTextDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 30px);
  padding: 15px 15px;
  background-color: #fff;
  border: 1px solid rgb(192, 192, 192);
  cursor: pointer;
}
.DDText {
  width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DDOptions {
  position: absolute;
  top: 54px;
  width: 100%;
  z-index: 5000;
  border: 1px solid grey;
}
.DDOption {
  width: calc(100% - 20px);
  padding: 5px 10px;
  cursor: pointer;
}
.DDOption:hover {
  color: var(--primary);
}
