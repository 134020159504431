/* LAYOUT */
.allContentDiv {
  background-color: #f1f5ff;
  min-height: 80vh;
  padding-bottom: 10vh;
  width: 100%;
  overflow-y: auto;
  margin: 0;
}
/* HEADER */
.headerMainDiv {
  width: 100%;
  height: 10vh;
  padding-top: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* CSS for background */
  background: var(--primary);
  color: white;
  /* Old browsers */
  background: -moz-linear-gradient(left, var(--primary) 0%, #3c29dc 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, var(--primary) 0%, #3c29dc 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, var(--primary) 0%, #3c29dc 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="var(--primary)", endColorstr="#3c29dc",GradientType=1 );
}
.profilePicture {
  height: 7vh;
  width: 7vh;
  border-radius: 50%;
  margin: 0 10px;
}
.backArrow {
  margin: 0 20px;
  font-size: larger;
  font-weight: 900;
  cursor: pointer;
}
.personBtn {
  margin: 0 20px;
  font-size: larger;
  font-weight: 700;
  cursor: pointer;
}
.headerInfoDiv {
  display: flex;
  align-items: center;
  color: white;
  font-size: large;
}
.pageName {
  border-right: 1px solid white;
  padding: 0 10px;
  font-size: 30px;
}

/* FOOTER */
.footerMainDiv2 {
  width: calc(100vw - 20px);
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  padding: 0 10px;
  color: white;
  font-size: 25px;
  /* CSS for background */
  background: var(--primary);
  /* Old browsers */
  background: -moz-linear-gradient(left, var(--primary) 0%, #3c29dc 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, var(--primary) 0%, #3c29dc 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, var(--primary) 0%, #3c29dc 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="var(--primary)", endColorstr="#3c29dc",GradientType=1 );
}
.homeBtn {
  border: 10px solid #e8ecff;
  border-radius: 50%;
  height: 8.5vh;
  width: 8.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: var(--primary);
  margin-bottom: 7vh;
}

/* Auth Footer CSS */
.StyledFabCss {
  background-color: rgb(243, 245, 255) !important;
  height: 70px !important;
  width: 70px !important;
  border-radius: 50%;
}
.footerMainDiv {
  background-color: rgb(255, 255, 255);
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.footerIcon {
  margin-top: 3;
}
.tooltipMainDiv {
  width: 100%;
  height: 100%;
  /* border-radius: 15px;
  padding: 20px; */
  background-color: var(--primary);
}

.tooltipText {
  width: 90%;
  color: white;
  font-size: 16px;
}

@media screen and (max-width: 1100px) {
  .backArrow {
    font-size: 40px;
  }
}

@media screen and (max-width: 900px) {
  .backArrow {
    font-size: 30px;
  }
}

@media screen and (max-width: 600px) {
  .backArrow {
    margin: 0 20px;
  }
  .pageName {
    font-size: medium;
  }
}
