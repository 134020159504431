.profileSettingsMainDiv {
  background-color: #f4f4fc;
}
.profileSettingsInnerMain {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  padding: 10vh 20px;
}
.sidePannel {
  width: 20vw;
  background-color: #fafafa;
  border-radius: 10px;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
}
.sidePannelHeading {
  padding: 20px 25px;
  text-transform: uppercase;
  color: var(--primary);
  border-bottom: 1px solid grey;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  width: calc(100% - 50px);
  /* display: flex; */
}
.referralEarningInAccount {
  text-transform: capitalize;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.sidePannelOptions {
  width: calc(100% - 50px);
  padding: 20px 25px;
  font-size: 13px;
  line-height: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
/* .sidePannelOptions:hover {
  background-color: #f4f4fc;
} */
.sidePannelOptionsIcon {
  color: var(--primary);
  font-weight: 200;
  margin: 0 15px 0 0;
}
.accountDetails {
  width: 65vw;
}
.accountDetailsHeading {
  font-size: 20px;
  padding: 0px 0px 1.5vw;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--primary);
}
.accountDetailsBox {
  padding: 2.25vw 0;
  border-top: 1px solid gray;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.accountDetailsBoxText {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary);
}
.accountDetailsBoxInputBox {
  width: calc(35vw - 40px);
  padding: 20px;
  background-color: white;
  color: gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
.accountDetailsBoxInputBoxLabelOneImage {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
.accountDetailsBoxInputBox2 {
  width: calc(100% - 40px);
  padding: 20px;
  background-color: white;
  color: gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin: 5px 0 20px;
}
.userProfileImageImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.accountDetailsBoxInputBoxLabel {
  font-weight: 300;
}
.accountDetailsBoxInputBoxInput {
  width: 80%;
  outline: none;
  border: none;
}
.accountDetailsBoxInputBoxInput:focus {
  outline: none;
  border: none;
}
.accountDetailsBoxInputBoxEditIcon {
  margin: 0px 0 0 5px;
  cursor: pointer;
}
.uploadImageSpan {
  color: var(--primary);
  font-weight: 700;
  cursor: pointer;
  background-color: #e5e4ec;
  border-radius: 8px;
  padding: 7px 16px;
  font-size: 14px;
}
.uploadImageInput {
  display: none;
}
.submitButton {
  background-color: var(--primary);
  border-radius: 6px;
  width: 150px;
  height: 40px;
  color: white;
  font-weight: bold;
  margin: 30px 0 0 0;
  float: right;
}
.submitButton:hover {
  color: #e5e4ec;
}
.submitButton:focus {
  outline: none;
  border: none;
}
.paymentHeading {
  font-size: 22px;
  font-weight: 500;
  color: var(--primary);
}
.paymentText {
  color: grey;
}
.paymentSec {
  margin: 10px 0 30px;
}
.cardMainBody {
  display: flex;
  justify-content: space-between;
}
.cardMainBodyCred {
  width: 500px;
}
/* Payment Methods Table */
.paymentTable {
  width: 100%;
  margin: 10px auto;
}
.paymentTableHeading {
  width: calc(80% + 2px);
  display: flex;
  color: white;
  background-color: var(--primary);
  padding: 3px 10%;
}
.paymentTableLine {
  width: 80%;
  display: flex;
  color: gray;
  background-color: white;
  border: 1px solid grey;
  border-top: none;
  padding: 1px 10%;
}
.paymentTableHeadingCard {
  width: 30%;
}
.paymentTableHeadingDefault {
  width: 30%;
}
.paymentTableHeadingDelete {
  width: 30%;
  color: transparent;
}
.paymentTableLineCard {
  width: 30%;
}
.paymentTableLineDefault {
  width: 30%;
}
.paymentTableLineDelete {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.paymentTableLineDeleteIcon {
  cursor: pointer;
}
.paymentTableLineDeleteIcon:hover {
  color: rgb(138, 1, 1);
}
.friendsReferralsText {
  margin: 0px 0 20px;
}
.friendsReferralsBox {
  /* display: flex;
  justify-content: center; */
  margin: 40px 0 10px;
}
.friendsReferralsBoxInput {
  width: 250px;
  padding: 10px;
  margin: 0 auto;
  border-radius: 5px;
}
.friendsReferralsBoxInput:focus {
  box-shadow: none;
  border: none;
  outline: none;
}
.centerFields {
  display: flex;
  justify-content: center;
}
.friendsReferralsBoxBtn {
  width: 250px;
  background-color: var(--primary);
  color: white;
  border-radius: 5px;
  margin: 10px auto;
  font-weight: 500;
}

/* .defaultPaymentCheckbox:focus {
  border: none;
} */
/* sidePannel */
@media screen and (max-width: 1024px) {
  .profileSettingsInnerMain {
    flex-wrap: wrap;
  }
  .sidePannel {
    width: 100%;
    justify-content: space-between;
    margin: 0 0 30px 0;
  }
  .accountDetails {
    width: 100%;
  }
  .sidePannelOptions {
    width: 30%;
  }
}
@media screen and (max-width: 600px) {
  .sidePannelOptions {
    width: 100%;
  }
  .cardMainBody {
    flex-wrap: wrap;
  }
  .accountDetailsBox {
    flex-wrap: wrap;
  }
  .accountDetailsBoxText {
    width: 100%;
    margin: 10px 0;
  }
  .accountDetailsBoxText3 {
    width: 100%;
    margin: 30px 0 0;
    color: rgb(202, 2, 2);
  }
  .accountDetailsBoxInputBox {
    width: 100%;
  }
  .cardMainBodyCred {
    width: 90%;
  }
}
