.s1Md {
  width: 100%;
}
.s1_top {
  width: calc(100% - 30vw);
  padding: 50px 15vw;
}
.s1H1 {
  color: var(--primary);
  font-weight: 800;
  width: 48%;
}
.s1H2 {
  color: var(--primary);
  margin-bottom: 25px;
}
.s1Txt {
  color: var(--text);
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: normal;
  width: 48%;
}
.s1_bottom {
  background-color: #ebebf9;
  width: calc(100% - 30vw);
  padding: 50px 15vw;
  display: flex;
  justify-content: space-between;
}
.s1_bottom_Left {
  width: 50%;
}
.s1ImagesDiv {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.mainForm {
  width: calc(48% - 50px);
  background-color: #fff;
  padding: 25px;
  border-radius: 15px;
  margin-top: -200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.fullInput {
  width: calc(100% - 30px);
  padding: 15px 15px;
  border: 1px solid rgb(192, 192, 192);
  margin: 8px 0;
  font-size: 100%;
}
.fullInput:focus {
  box-shadow: none;
  outline: none;
}
.submitBtn {
  color: var(--primary);
  background-color: var(--secondary);
  border-radius: 50px;
  padding: 10px 34px;
  font-weight: bold;
  margin: 10px auto;
}
.submitBtn:hover {
  background-color: var(--primary);
  color: #fff;
}
.dropdownMainDiv {
  width: 100%;
  margin: 10px 0;
  border: 1px solid rgb(192, 192, 192);
}
.dropdownSelect {
  width: calc(100% - 30px);
  padding: 15px 15px;
  background-color: #fff;
  color: var(--text);
  border: none;
  outline: none;
}
.dropdownSelect:focus {
  border: none;
  outline: none;
}
.s2Md {
  width: calc(100% - 30vw);
  padding: 50px 15vw;
}
.s2L1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s2CMd {
  width: calc(33% - 15px);
}
.s2CImgD {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s2CImgTag {
  height: 80px;
  margin-bottom: 25px;
}
.s2CardH {
  color: var(--primary);
  text-align: center;
}
.s2CardT {
  color: var(--text);
  text-align: center;
}
.s2L2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
.s3TopMd {
  width: calc(100% - 30vw);
  padding: 50px 15vw;
}
.s3H {
  text-align: center;
  margin-bottom: 55px;
  color: var(--primary);
}
.s3Flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.s3FlexL {
  width: 48%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.s3FlexLH {
  color: var(--primary);
  margin-bottom: 25px;
  font-size: 32px;
  width: 100%;
}
.s3FlexLTxt {
  color: var(--text);
}
.s3A_Md {
  width: calc(48% - 30px);
  background-color: #fff;
  padding: 15px;
  border: 1px solid var(--primary);
  border-radius: 10px;
}
.s3A_Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border);
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.s3A_TopL {
  display: flex;
  align-items: center;
}
.s3A_TopLImg {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  color: var(--primary);
}
.s3A_TopLH {
  color: var(--primary);
  font-size: 25px;
  margin-left: 10px;
}
.s3A_TopLTxt {
  font-size: 14px;
  color: var(--text);
  display: block;
  margin-top: -10px;
  margin-left: 10px;
}
.s3A_TopR {
  max-width: 100%;
  width: 220px;
  margin-top: -35px;
  margin-bottom: -30px;
}
.s3A_Top2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--secondary);
  margin: 15px 0;
}
.s3A_Top2Txt {
  font-size: 18px;
}
.s3A_Line {
  width: calc(100% - 30px);
  background-color: #fff;
  padding: 15px;
  box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.s3A_LineInfo {
  color: var(--primary);
  font-size: 18px;
  margin-bottom: 0;
}
.s3A_LineHeading {
  font-size: 18px;
}
.s3A_LineTxt {
  color: var(--text);
  margin-top: 3px;
  font-size: 13px;
  display: block;
}
.s3A_LineCheckbox {
  color: var(--secondary);
}
.s3FlexLImg {
  width: 80%;
}
.s3FlexL2Img {
  width: 80%;
  margin-left: "auto";
}
.s3FlexWhite {
  width: calc(100% - 30vw);
  background-color: #fff;
  padding: 60px 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s4Md {
  width: calc(100% - 30vw);
  padding: 50px 15vw;
}
.s4H {
  color: var(--primary);
  margin-bottom: 55px;
  text-align: center;
}
.s4List {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.s4ListItem {
  width: calc(25% - 24px);
  padding: 0 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.s4Img {
  margin: 0 auto 15px;
  width: 70px;
}
.s4ListH {
  color: var(--primary);
  margin-bottom: 35px;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .s1_top {
    width: calc(100% - 6vw);
    padding: 50px 3vw;
  }
  .s1_bottom {
    width: calc(100% - 6vw);
    padding: 50px 3vw;
  }
  .mainForm {
    width: 50%;
    margin-top: -120px;
  }
  .s2Md {
    width: calc(100% - 6vw);
    padding: 50px 3vw;
  }
  .s3TopMd {
    width: calc(100% - 6vw);
    padding: 50px 3vw;
  }
  .s3FlexWhite {
    width: calc(100% - 6vw);
    padding: 50px 3vw;
  }
  .s4Md {
    width: calc(100% - 6vw);
    padding: 50px 3vw;
  }
  .s3A_TopR {
    width: 180px;
  }
}
@media screen and (max-width: 768px) {
  .s3A_TopR {
    width: 160px;
  }
}

@media screen and (max-width: 600px) {
  .s1H1 {
    width: 100%;
  }
  .s1Txt {
    width: 100%;
    font-size: 16px;
  }
  .s1_top {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
  }
  .s1_bottom {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .mainForm {
    width: calc(100% - 50px);
    margin-top: 0;
  }
  .s1_bottom_Left {
    width: 100%;
    margin-top: 30px;
  }
  .s2Md {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
  }
  .s2L2 {
    flex-wrap: wrap;
  }
  .s2CMd {
    width: 100%;
    margin-bottom: 15px;
  }
  .s3TopMd {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
  }
  .s3FlexWhite {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
  }
  .s3Flex {
    flex-wrap: wrap;
  }
  .s3FlexL {
    width: 100%;
    margin-bottom: 15px;
  }
  .s3FlexWhite {
    flex-wrap: wrap;
  }
  .s3FlexL2Img {
    margin: 0 auto;
  }
  .s3A_Md {
    width: 100%;
    margin-bottom: 15px;
  }
  .s4Md {
    width: calc(100% - 6vw);
    padding: 30px 3vw;
  }
  .s4ListItem {
    width: calc(50% - 24px);
  }
}
