.loginMainDiv {
  width: calc(100% - 64px);
  background-color: white;
  margin: 12px;
  padding: 20px;
  box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.25);
}
.loginForm {
  width: calc(34vw - 60px);
  margin: 75px 5vw;
  background-color: white;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.passwordInput {
  border: none;
  outline: none;
  width: calc(97% - 40px);
  padding: 10px;
  font-size: 100%;
  border: none;
  outline: none;
  color: gray;
}
.loginImage {
}
.posterMainHeading {
  color: var(--primary);
  font-size: 30px;
  line-height: 31px;
  font-weight: 700;
  letter-spacing: 0.5px;
  width: 100%;
  margin: 15px 0 10px;
}
.posterText {
  color: var(--primary);
  font-size: 22px;
  line-height: 23px;
  font-weight: 500;
  margin: 20px 0;
}
.posterText2 {
  color: var(--primary);
  margin: 20px 0;
}
.inputBox {
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  border: 1px solid rgb(192, 192, 192);
  color: rgb(192, 192, 192);
  margin: 15px 0;
  padding: 3px 10px;
}
.fullInput {
  width: calc(97% - 20px);
  padding: 10px;
  font-size: 100%;
  border: none;
  outline: none;
  color: gray;
}
.fullInput:focus {
  box-shadow: none;
  outline: none;
}
.fullInput::placeholder {
  color: rgb(198, 198, 198);
}
.iconPlacement {
  position: relative;
  top: -45px;
  left: 10px;
  color: gray;
  display: flex;
  width: 30%;
}
.TnCMainDiv {
  font-size: 90%;
  color: grey;
  margin: 20px 0 10px;
  font-size: small;
}
.TnCCheckbox {
  border-radius: 3px;
  margin: 0 4px 0 0;
  height: 12px;
  width: 12px;
}
.TnCCheckbox:focus {
  box-shadow: none;
}
.TnCCheckbox:hover {
  color: var(--primary);
}
.signupBtnMainDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: larger;
  color: var(--primary);
  font-weight: 500;
  margin: 30px 0;
}
.signupBtn {
  background-color: var(--secondary);
  border-radius: 10px;
  width: 155px;
  height: 38px;
  font-weight: 500;
  font-size: medium;
  color: var(--primary);
}
.signupBtn:hover {
  color: white;
  background-color: var(--primary);
}
.signinTxtForgotPassword {
  color: var(--primary);
  cursor: pointer;
  margin-bottom: 1.1%;
  font-size: medium;
}
.signinTxt {
  color: var(--primary);
  font-weight: 700;
}
.signinTxt:hover {
  color: var(--secondary);
}
.signinRedirect {
  color: rgb(192, 192, 192);
  font-size: medium;
}

@media screen and (max-width: 900px) {
  .loginForm {
    width: 50%;
  }
  .signupBtnMainDiv {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  .loginForm {
    width: calc(100% - 30px);
  }
}
