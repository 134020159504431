.mainDiv {
  width: 100%;
}
.cardMainDiv {
  background-color: #fff;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 00.1);
  width: calc(85% - 40px);
  margin: 10% auto;
  padding: 20px;
  border-radius: 20px;
}
.headingMainDiv {
  color: var(--primary);
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin: 5px 0;
}
.subHeading {
  font-weight: 500;
  color: rgb(177, 177, 177);
  font-size: 22px;
  text-align: center;
}
.generalText {
  color: var(--primary);
  margin: 20px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.buttonGroup {
  /* margin: 20px 0 0; */
}
.Btn1Style {
  height: 45px;
  width: 200px;
  border-radius: 22px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary);
  color: var(--primary);
  margin: 10px auto;
}
.Btn1Style:hover {
  background-color: var(--primary);
  color: #fff;
}
.Btn2Style {
  height: 45px;
  width: 200px;
  border-radius: 22px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: #fff;
  margin: 10px auto;
}
.Btn2Style:hover {
  background-color: white;
  border: 1px solid var(--primary);
  color: var(--primary);
}
